import externalLinkIcon from "../../../../assets/dashboard/external-link.svg";

const CampaignButton = () => {
  const goToChachingSignIn = () => {
    window.open(process.env.REACT_APP_CHACHING_SIGN_IN, "_blank", "noreferrer");
  };

  return (
    <button
      className="go-to-campaign-button"
      onClick={() => {
        goToChachingSignIn();
      }}
    >
      Go to campaigns
      <img
        className="external-link-icon"
        alt="go to campaign"
        src={externalLinkIcon}
      />
    </button>
  );
};

export { CampaignButton };
