import { useState } from "react";
import { Alert } from "../Dasbhoard/Misc/Alert";
import { InstallationStep } from "./InstallationStep";
import StepTwoImage from "../../assets/pixel-installation-page/StepTwo.png";
import StepThreeImage from "../../assets/pixel-installation-page/StepThree.png";
import StepFourImage from "../../assets/pixel-installation-page/StepFour.png";
import StepFiveImage from "../../assets/pixel-installation-page/StepFive.png";
import StepSixImage from "../../assets/pixel-installation-page/StepSix.png";
import StepSevenImage from "../../assets/pixel-installation-page/StepSeven.png";
import StepNineImage from "../../assets/pixel-installation-page/StepNine.png";
import StepTenImage from "../../assets/pixel-installation-page/StepTen.png";

const PixelPageMainContent = () => {
  const customTrackingCode = `<script>
  const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let chachingc = urlParams.get("chachingc");
if (chachingc != null) {
  sessionStorage.setItem("chachingc", chachingc);
}

const orderFunction = urlParams.get("function");
let orderNumber = urlParams.get("ordernumber");

const checkForConversion = async () => {
  const orderIsValid = orderFunction === "PAYMENTCOMPLETE";
  if (orderIsValid) {
    chachingc = sessionStorage.getItem("chachingc");
    const username = urlParams.get("UN");

    const conversionIsValid =
      orderNumber != null && chachingc != null && username != null;

    if (conversionIsValid) {
      orderNumber = orderNumber.includes("/")
        ? orderNumber.split("/")[0]
        : orderNumber;

      try {
        const response = await fetch(
          "${process.env.REACT_APP_XANO_API}/ekm/order_confirmation",
          {
            body: JSON.stringify({
              merchant_username: username,
              order_id: orderNumber,
              click_id: chachingc,
            }),
            headers: {
              "Content-Type": "application/json",
              "X-Data-Source": "${process.env.REACT_APP_X_DATA_SOURCE}"
            },
            method: "POST",
          }
        );
        const content = await response.json();
      } catch (error) {
        console.log(error);
      }
      sessionStorage.removeItem("chachingc");
    }
  }
};
checkForConversion();

  </script>`;

  const [isCopied, setIsCopied] = useState(false);

  const handleIsCopied = () => {
    navigator.clipboard.writeText(customTrackingCode);

    if (isCopied === true) {
      return;
    }

    setIsCopied(true);
    setTimeout(() => {
      // remove element from DOM
      setIsCopied(false);
    }, 8000);
  };

  return (
    <div className="install-page-content">
      <div className="layout">
        <div className="heading-text">
          EKM Pixel Conversion Manual Installation
        </div>
        <div className="panel">
          <InstallationStep
            stepNumber={1}
            content={"Navigate to your EKM Dashboard Page"}
          />
          <InstallationStep
            stepNumber={2}
            content="From the Dashboard, click Features on the left-hand menu:"
            image={StepTwoImage}
          />
          <InstallationStep
            stepNumber={3}
            content="Click the Feature Library tab:"
            image={StepThreeImage}
          />
          <InstallationStep
            stepNumber={4}
            content="Scroll down to the Conversions features and click the View More button on the right-hand side:"
            image={StepFourImage}
          />
          <InstallationStep
            stepNumber={5}
            content="Scroll down and click the Google Tools icon:"
            image={StepFiveImage}
          />
          <InstallationStep
            stepNumber={6}
            content="Click the green Install Feature button:"
            image={StepSixImage}
          />
          <InstallationStep
            stepNumber={7}
            content="To load the feature, click on the Google Tools icon in the Installed Features page:"
            image={StepSevenImage}
          />

          <div className="step">
            <div className="step-text">
              <div className="step-number-container">
                <p>08</p>
              </div>
              <p>Copy the following tracking code:</p>
            </div>
            <div
              className="code-block"
              onClick={() => {
                handleIsCopied();
              }}
            >
              {customTrackingCode}
            </div>
          </div>
          <InstallationStep
            stepNumber={9}
            content="Paste the tracking code at the top Google Analytics Tracking Code input field:"
            image={StepNineImage}
          />
          <InstallationStep
            stepNumber={10}
            content="Click the Save button:"
            image={StepTenImage}
          />

          {isCopied && <Alert alertMessage={"Copied To Clipboard"} />}
        </div>
      </div>
    </div>
  );
};

export { PixelPageMainContent };
