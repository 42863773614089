import "./css/global.css";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { LandingPage } from "./pages/Landing/LandingPage";
import { DashboardPage } from "./pages/Dasbhoard/DashboardPage";
import { PixelInstallPage } from "./pages/PixelInstallation/PixelInstallPage";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/connect-chaching" element={<LandingPage />} />
          <Route path="/ekm-dashboard" element={<DashboardPage />} />
          <Route path="/installation" element={<PixelInstallPage />} />
          <Route path="/*" element={<Navigate to="/connect-chaching" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
