const InstallationStep = ({ stepNumber, content, image }) => {
  const formatStepNumber = () => {
    return stepNumber > 9 ? "" + stepNumber : "0" + stepNumber;
  };

  return (
    <div className="step">
      <div className="step-text">
        <div className="step-number-container">
          <p>{formatStepNumber()}</p>
        </div>
        <p>{content}</p>
      </div>
      {image && (
        <img
          className={`image${stepNumber}`}
          alt="installation step"
          src={image}
        />
      )}
    </div>
  );
};

export { InstallationStep };
