import "../../css/layout/dashboard.css";
import { DashboardOverviewCard } from "./OverviewCard/DashboardOverviewCard";
import { DashboardResourcesCard } from "./ResourcesCard/DashboardResources";
import { DashboardProductsStatusCard } from "./ListedProductsCard/DashboardProductsStatusCard/DashboardProductsStatusCard";
import { DashboardCampaignsStatusCard } from "./CampaignCard/DashboardCampaignsStatusCard/DashboardCampaignsStatusCard";
import { DashboardProductTableCard } from "./ProductIssuesCard/DashboardProductTableCard";
import { DashboardContextProvider } from "../../context/DasbhoardContext";
import { DisconnectModal } from "./Misc/DisconnectModal";
import { Alerts } from "./Misc/Alerts";
import { useEffect, useRef } from "react";
import { DisconnectModalContextProvider } from "../../context/DisconnectModalContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { chachingXano } from "../../utils/chachingXanoAPI";

const DashboardMainContent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current === false) {
      const authenticateIntoEKMAccount = async () => {
        // state is not necessary and so can be null
        let scope = searchParams.get("scope");
        let code = searchParams.get("code");
        let session_state = searchParams.get("session_state");
        let error = searchParams.get("error");

        if (scope && code) {
          try {
            const response = await chachingXano.post("/ekm/authorize", {
              scope: scope,
              code: code,
              state: session_state,
            });

            localStorage.setItem(
              "ekm_user_uuid",
              response.data.user_record.uuid
            );

            searchParams.delete("scope");
            searchParams.delete("code");
            searchParams.delete("session_state");
            setSearchParams(searchParams);

            console.log("EKM User linked");
          } catch (error) {
            console.log(error);

            const EKMUserAlreadyLinked =
              localStorage.getItem("ekm_user_uuid") != null;

            if (
              error.response.status === 401 &&
              EKMUserAlreadyLinked === true
            ) {
              return;
            } else {
              navigate("/connect-chaching");
            }
          }
        } else if (error) {
          navigate("/connect-chaching");
        } else {
          const ekmUserConnected =
            window.localStorage.getItem("ekm_user_uuid") != null;

          if (!ekmUserConnected) navigate("/connect-chaching");
        }
      };

      authenticateIntoEKMAccount();

      return () => {
        mounted.current = true;
      };
    }
  });

  const redirectUserIfNoEKMuserUUID = () => {
    if (!window.localStorage.getItem("ekm_user_uuid")) {
      localStorage.removeItem("xano_auth_token");
      localStorage.removeItem("xano_refresh_token");
      localStorage.removeItem("xano_secret_key");
      localStorage.removeItem("xano_expiry_timestamp");
      localStorage.removeItem("accountID");
      localStorage.removeItem("tracking-code-added");
      localStorage.removeItem("access_token");

      navigate("/connect-chaching");
    }
  };

  // // on mount
  // useEffect(() => {
  //   setTimeout(() => {
  //     redirectUserIfNoEKMuserUUID();
  //   }, 4000);
  // }, []);

  // if user deletes ekm user UUID from local storage
  window.onstorage = () => {
    redirectUserIfNoEKMuserUUID();
  };

  return (
    <DashboardContextProvider>
      <DisconnectModalContextProvider>
        <div className="main-content">
          <div className="section-container">
            <DashboardOverviewCard />
            <DashboardCampaignsStatusCard />
            <DashboardProductsStatusCard />
            <DashboardProductTableCard />
            <DashboardResourcesCard />
          </div>
        </div>
        <Alerts />
        <DisconnectModal />
      </DisconnectModalContextProvider>
    </DashboardContextProvider>
  );
};

export { DashboardMainContent };
