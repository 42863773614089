import { useEffect, useRef, useState } from "react";
import { UseDashboardContext } from "../../../context/DasbhoardContext";

const LastSyncElement = () => {
  const [lastSync, setLastSync] = useState("");

  const { isConnected } = UseDashboardContext();
  let checkedConnection = useRef(false);

  useEffect(() => {
    if (checkedConnection.current === false) {
      const checkConnection = () => {
        if (isConnected === true) {
          setLastSync(new Date().toLocaleString());

          return () => (checkedConnection.current = true);
        }
      };

      checkConnection();
    }
  }, [isConnected, checkedConnection]);

  return (
    isConnected === true && (
      <div className="supporting-text">Last sync: {lastSync}</div>
    )
  );
};

export { LastSyncElement };
