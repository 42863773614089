import { UseDashboardContext } from "../../../context/DasbhoardContext";
import exclamationMark from "../../../assets/dashboard/error.svg";
import { UseDisconnectModalContext } from "../../../context/DisconnectModalContext";

const DisconnectModal = () => {
  const { setAccessToken, setAccountID, setTrackingCodeAdded } =
    UseDashboardContext();

  const { modalVisible, setModalVisible, setLoggedOut } =
    UseDisconnectModalContext();

  const handleCancelButtonClick = () => {
    setModalVisible(false);
  };

  const handleDisconnectButtonClick = () => {
    setModalVisible(false);

    logOutUser();
  };

  const logOutUser = () => {
    localStorage.removeItem("xano_auth_token");
    localStorage.removeItem("xano_refresh_token");
    localStorage.removeItem("xano_secret_key");
    localStorage.removeItem("xano_expiry_timestamp");

    localStorage.removeItem("access_token");
    localStorage.removeItem("accountID");
    localStorage.removeItem("tracking-code-added");
    setAccessToken(null);
    setAccountID(null);
    setTrackingCodeAdded(false);

    setLoggedOut(true);
  };

  return (
    modalVisible === true && (
      <div className="modal-background">
        <div className="modal-container">
          <div className="modal-title-container">
            <img
              src={exclamationMark}
              alt="exclamation mark icon"
              className="modal-icon"
            />
            <h2 className="modal-title">
              Are you sure you want to disconnect your account?
            </h2>
          </div>
          <div className="modal-description">
            Disconnecting your account could lead to lost progress on your
            campaigns.
          </div>
          <div className="modal-buttons-container">
            <button
              className="white-button"
              onClick={() => {
                handleCancelButtonClick();
              }}
            >
              cancel
            </button>
            <button
              className="red-button"
              onClick={() => {
                handleDisconnectButtonClick();
              }}
            >
              Yes, Disconnect
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export { DisconnectModal };
