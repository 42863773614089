import { useNavigate } from "react-router-dom";
import gearsImage from "../../assets/landing-page/howitworksadvertisers.svg";

import groupImages from "../../assets/landing-page/group-8113@2x.png";
import { ConnectButton } from "./ConnectButton";
const LandingPageMainContent = () => {
  return (
    <>
      <div className="landing-page">
        <img
          className="how-it-works-advertisers-icon"
          alt="how-it-works-icon"
          src={gearsImage}
        />
        <div className="connect-chaching-container">
          <div className="drive-incremental-sales">
            Drive incremental sales by adding a powerful additional advertising
            channel.
          </div>
          <ConnectButton />
        </div>
        <img className="landing-page-image" alt="" src={groupImages} />
      </div>
    </>
  );
};

export { LandingPageMainContent };
