import { useState } from "react";
import moreIcon from "../../../assets/dashboard/more_vert.svg";
import { UseDashboardContext } from "../../../context/DasbhoardContext";
import { UseDisconnectModalContext } from "../../../context/DisconnectModalContext";

const DisconnectSelectButton = () => {
  const { accessToken, accountID, trackingCodeAdded } = UseDashboardContext();

  const { setModalVisible } = UseDisconnectModalContext();

  const [popupVisible, setPopupVisible] = useState(false);

  const handleIconClick = () => {
    if (popupVisible === false) setPopupVisible(true);
    if (popupVisible === true) setPopupVisible(false);
  };
  return (
    accessToken &&
    accountID &&
    trackingCodeAdded && (
      <>
        <img
          src={moreIcon}
          alt="more"
          className="disconnect-icon"
          onClick={() => {
            handleIconClick();
          }}
        />
        {popupVisible === true && (
          <div
            className="disconnect-popup-container"
            onMouseLeave={() => {
              setPopupVisible(false);
            }}
          >
            <div className="disconnect-popup">
              <button
                className="disconnect-button"
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                Disconnect
              </button>
            </div>
          </div>
        )}
      </>
    )
  );
};

export { DisconnectSelectButton };
