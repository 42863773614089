import { UseSignInContext } from "../../../../context/SignInContext";

const SignInAndSignUpButtons = () => {
  const { loginVisible, setLoginVisible } = UseSignInContext();

  const goToChachingSignUp = () => {
    window.open(process.env.REACT_APP_CHACHING_SIGN_UP, "_blank", "noreferrer");
  };

  const showLogin = () => {
    setLoginVisible(true);
  };

  return (
    !loginVisible && (
      <div className="connect-buttons-container">
        <button
          className="dark-blue-button"
          onClick={() => goToChachingSignUp()}
        >
          sign up
        </button>
        <div className="or">OR</div>
        <button className="dark-blue-button" onClick={() => showLogin()}>
          Connect existing account
        </button>
      </div>
    )
  );
};

export { SignInAndSignUpButtons };
