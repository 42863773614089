import { UseDashboardContext } from "../../../../context/DasbhoardContext";
import { SignInAndSignUpButtons } from "./SignInAndSignUpButtons";
import { SignInForm } from "./SignInForm";

const SignInStep = () => {
  const { accessToken } = UseDashboardContext();

  return (
    !accessToken && (
      <>
        <div className="help-text">
          To use ChaChing, you will need to connect your account. Please select
          an option below to proceed. If you are signing up for the first time,
          once you create your user and merchant account on ChaChing, you must
          connect your account here.
        </div>
        <SignInAndSignUpButtons />
        <SignInForm />
      </>
    )
  );
};

export { SignInStep };
