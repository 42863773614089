import footerCircle from "../../assets/landing-page/footer-circle.svg";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-paragraph-container">
        <p className="footer-paragraph-1">
          ChaChing is a fixed cost-per-order channel built in partnership with
          some of the world’s leading companies, for everyone.
        </p>
        <p className="footer-paragraph-2">
          Learn more at&nbsp;
          <a href="https://www.chaching.me" className="chaching-link">
            chaching.me
          </a>
        </p>
        <img className="footer-circle" alt="footer circle" src={footerCircle} />
      </div>
    </div>
  );
};

export { Footer };
