import { createContext, useContext } from "react";
import { useState } from "react";

export const DisconnectModalContext = createContext();

export const DisconnectModalContextProvider = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);

  return (
    <DisconnectModalContext.Provider
      value={{
        modalVisible,
        setModalVisible,
        loggedOut,
        setLoggedOut,
      }}
    >
      {props.children}
    </DisconnectModalContext.Provider>
  );
};

export const UseDisconnectModalContext = () => {
  return useContext(DisconnectModalContext);
};
