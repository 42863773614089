import { useSearchParams } from "react-router-dom";
import { Header } from "../../components/Header";
import "../../css/layout/landing-page.css";

// import { Header } from "../../components/Header";
import { Footer } from "./Footer";
import { LandingPageMainContent } from "./LandingPageMainContent";

const LandingPage = () => {
  return (
    <>
      <Header />
      <LandingPageMainContent />
      <Footer />
    </>
  );
};

export { LandingPage };
