import { useNavigate } from "react-router-dom";

const ConnectButton = () => {
  const navigate = useNavigate();
  const handleConnect = () => {
    const ekmUserIsConnected = localStorage.getItem("ekm_user_uuid") != null;
    if (ekmUserIsConnected) {
      navigate("/ekm-dashboard");
    } else {
      window.location.href = process.env.REACT_APP_CONSENT_URL;
    }
  };

  return (
    <button className="connect-button" onClick={() => handleConnect()}>
      Connect ChaChing
    </button>
  );
};

export { ConnectButton };
