import { createContext, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { chachingXano } from "../utils/chachingXanoAPI";
import { useNavigate } from "react-router-dom";

export const DashboardContext = createContext();

export const DashboardContextProvider = (props) => {
  const navigate = useNavigate();

  // statuses to determine where the user is in the sign in flow
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("access_token")
  );

  const [failedLoginCount, setFailedLoginCount] = useState(null);
  const [accountID, setAccountID] = useState(localStorage.getItem("accountID"));
  const [trackingCodeAdded, setTrackingCodeAdded] = useState(
    localStorage.getItem("tracking-code-added")
  );

  // is it connected to xano (auth token up to date)
  const [isConnected, setIsConnected] = useState(false);
  const checksPerformed = useRef(false);

  useEffect(() => {
    checkStateOfConnection();
  }, [accessToken, accountID, trackingCodeAdded]);

  const checkStateOfConnection = async () => {
    // user has gone through sign in flow
    const isReadyToConnect = accessToken && accountID && trackingCodeAdded;

    const isLoggedOut = !(accessToken && accountID && trackingCodeAdded);

    if (checksPerformed.current === false && isReadyToConnect) {
      await refreshAuthTokenIfExpired();
      checkUserIsStillConnectedToEKM();
      return () => (checksPerformed.current = true);
    } else if (isLoggedOut) {
      setIsConnected(false);
      return () => (checksPerformed.current = false);
    }
  };

  const refreshAuthTokenIfExpired = async () => {
    const expiry_timestamp = localStorage.getItem("xano_expiry_timestamp");
    const now = Date.now() / 1000;

    const accessTokenHasExpired = now + 30 > expiry_timestamp;
    if (accessTokenHasExpired) {
      // added 30 seconds in case the api call takes a while
      const isRefreshed = await refreshAuthToken();

      if (!isRefreshed) return;
    }

    setIsConnected(true);
  };

  const refreshAuthToken = async () => {
    const refresh_data = {
      refresh_token: localStorage.getItem("xano_refresh_token"),
      auth_token: localStorage.getItem("xano_auth_token"),
      secret_key: localStorage.getItem("xano_secret_key"),
    };

    try {
      const response = await chachingXano.post(`auth/refresh`, refresh_data);

      console.log(response);
      const { auth_token, refresh_token, secret_key, expiry } =
        response.data.data;

      const expiryTimestamp = Date.now() / 1000 + expiry;

      localStorage.setItem("xano_auth_token", auth_token);
      localStorage.setItem("xano_refresh_token", refresh_token);
      localStorage.setItem("xano_secret_key", secret_key);
      localStorage.setItem("xano_expiry_timestamp", expiryTimestamp);

      const isRefreshed = true;
      return isRefreshed;
    } catch (error) {
      removeUsersXanoAuthenticationDetails();
      removeUsersLocallyStoredChachingDetails();
      console.log(error);
      const isRefreshed = false;
      return isRefreshed;
    }
  };

  const checkUserIsStillConnectedToEKM = async () => {
    const ekm_user_uuid = localStorage.getItem("ekm_user_uuid");

    try {
      const response = await chachingXano.get(
        `ekm/${ekm_user_uuid}/auth_status`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("xano_auth_token")}`,
          },
        }
      );

      const isNotConnectedToEKM = response.data.auth_status !== 200;
      if (isNotConnectedToEKM) {
        console.log("Is not connected To EKM Error");
        removeUsersXanoAuthenticationDetails();
        removeUsersLocallyStoredChachingDetails();

        localStorage.removeItem("ekm_user_uuid");

        navigate("/connect-chaching");
      }
      return;
    } catch (error) {
      // Firefox treats an aborted request as an error, we do not treat this as an error (e.g. refresh causing an aborted request is not an error)
      if (
        error.message !== "Request aborted" &&
        error.message !== "Network Error"
      ) {
        removeUsersXanoAuthenticationDetails();
        removeUsersLocallyStoredChachingDetails();
        localStorage.removeItem("ekm_user_uuid");
        navigate("/connect-chaching");
      }
    }
  };
  const removeUsersXanoAuthenticationDetails = () => {
    localStorage.removeItem("xano_auth_token");
    localStorage.removeItem("xano_refresh_token");
    localStorage.removeItem("xano_secret_key");
    localStorage.removeItem("xano_expiry_timestamp");
  };

  const removeUsersLocallyStoredChachingDetails = () => {
    localStorage.removeItem("accountID");
    localStorage.removeItem("tracking-code-added");
    localStorage.removeItem("access_token");

    setAccountID(null);
    setAccessToken(null);
  };

  return (
    <DashboardContext.Provider
      value={{
        isConnected,
        accessToken,
        setAccessToken,
        accountID,
        setAccountID,
        trackingCodeAdded,
        setTrackingCodeAdded,
        failedLoginCount,
        setFailedLoginCount,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

export const UseDashboardContext = () => {
  return useContext(DashboardContext);
};
