import closeIcon from "../../../assets/dashboard/close.svg";
import checkIcon from "../../../assets/dashboard/check_circle.svg";
import errorIcon from "../../../assets/dashboard/error.svg";
import { useEffect, useState } from "react";

const Alert = ({ alertMessage, error }) => {
  const [hide, setHide] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);

      setTimeout(() => {
        // remove element from DOM
        setHide(true);
        setFadeOut(false);
      }, 700);
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  const hideAlert = () => {
    setHide(true);
  };

  const getAlertClass = () => {
    if (error === true) {
      return fadeOut ? "alert-container hide red" : "alert-container red";
    } else {
      return fadeOut ? "alert-container hide " : "alert-container";
    }
  };

  return (
    <>
      {hide === false && (
        <div className={getAlertClass()}>
          <img
            src={error === true ? errorIcon : checkIcon}
            alt="check"
            className="alert-check-icon"
          />
          <div className="alert-text">{alertMessage}</div>
          <img
            src={closeIcon}
            alt="close"
            className="alert-close-icon"
            onClick={() => {
              hideAlert();
            }}
          />
        </div>
      )}
    </>
  );
};

export { Alert };
