import refreshIcon from "../../../assets/dashboard/refresh.svg";

const RefeshButton = () => {
  const triggerRefresh = () => {
    window.location.reload();
  };

  return (
    <div
      className="refresh-button"
      onClick={() => {
        triggerRefresh();
      }}
    >
      <img className="refresh-icon" alt="refresh icon" src={refreshIcon} />
      Refresh
    </div>
  );
};

export { RefeshButton };
