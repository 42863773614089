import { Alert } from "./Alert";
import { UseDashboardContext } from "../../../context/DasbhoardContext";
import { useMemo } from "react";
import { UseDisconnectModalContext } from "../../../context/DisconnectModalContext";
const Alerts = () => {
  const {
    accessToken,
    accountID,
    trackingCodeAdded,
    isConnected,
    failedLoginCount,
  } = UseDashboardContext();

  const { loggedOut } = UseDisconnectModalContext;

  const alertStatus = useMemo(() => {
    if (failedLoginCount) {
      return "incorrect detail";
    }

    if (accessToken && !accountID) return "logged in";

    if (accessToken && accountID && !trackingCodeAdded)
      return (
        // <Alert alertMessage="You have successfully connected your account. Please install the tracking tag on your storefront" />
        "account connected"
      );
    if (isConnected === true)
      return (
        // <Alert alertMessage="You have successfully connected to ChaChing" />
        "successfully connected"
      );
    if (loggedOut === true && !accessToken && !accountID && !trackingCodeAdded)
      return (
        // <Alert alertMessage="You have successfully disconnected from ChaChing" />
        "successfully disconnected"
      );

    return null;
  }, [
    loggedOut,
    accessToken,
    accountID,
    trackingCodeAdded,
    isConnected,
    failedLoginCount,
  ]);

  return (
    <>
      {failedLoginCount && (
        <Alert
          alertMessage="Your email or password is incorrect. Please try again"
          error={true}
          key={failedLoginCount}
        />
      )}
      {alertStatus === "logged in" && (
        <Alert alertMessage="You have successfully logged in. Please connect your account" />
      )}
      {alertStatus === "account connected" && (
        <Alert alertMessage="You have successfully connected your account. Please install the tracking tag on your storefront" />
      )}
      {alertStatus === "successfully connected" && (
        <Alert alertMessage="You have successfully connected to ChaChing" />
      )}
      {alertStatus === "successfully disconnected" && (
        <Alert alertMessage="You have successfully disconnected from ChaChing" />
      )}
    </>
  );
  // return <div>1</div>;
};

export { Alerts };
