import "../css/components/header.css";
import chachingLogo from "../assets/header/logo.svg";

const Header = () => {
  return (
    <>
      <header className="header">
        <img className="chaching-logo" alt="Chaching" src={chachingLogo} />
        <div className="search">
          <div className="multi-column-layoutssearch-fi">
            <img className="search-icon" alt="" src="./search.svg" />
            <div className="placeholder">Search projects</div>
          </div>
        </div>
        <div className="header-options">
          <a
            className="documentation"
            href="https://chachingme.zendesk.com/hc/en-us"
          >
            Documentation
          </a>
          <a className="support" href="https://chachingme.zendesk.com/hc/en-us">
            Support
          </a>
        </div>
      </header>
    </>
  );
};

export { Header };
