import exclamationCircleIcon from "../../../assets/dashboard/exclamation-circle.svg";
import checkIcon from "../../../assets/dashboard/check.svg";
import clockIcon from "../../../assets/dashboard/clock.svg";

import { chachingXano } from "../../../utils/chachingXanoAPI";

import { useEffect, useRef, useState } from "react";
import { UseDashboardContext } from "../../../context/DasbhoardContext";

const ProductsStatus = () => {
  const { isConnected, accountID, setAccountID, setAccessToken } =
    UseDashboardContext();
  const productStatsRetrieved = useRef(false);

  const [listedAmount, setListedAmount] = useState(0);
  const [errorAmount, setErrorAmount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);

  useEffect(() => {
    if (isConnected && productStatsRetrieved.current === false) {
      const fetchData = async () => {
        try {
          const response = await chachingXano.get(
            `/chaching/accounts/${accountID}/products_status`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "xano_auth_token"
                )}`,
              },
            }
          );

          const chachingIsDisconnected =
            response.data.payload === "Unable To Refresh Token";

          if (chachingIsDisconnected) {
            console.log("Chaching Is Disconnected");
            signOutUser();
          }

          setListedAmount(response.data.listed);
          setErrorAmount(response.data.errors);
          setPendingAmount(response.data.pending);
        } catch (err) {
          console.log(err);
        }
      };

      fetchData();
      return () => (productStatsRetrieved.current = true);
    }
  }, [isConnected]);

  const signOutUser = () => {
    localStorage.removeItem("xano_auth_token");
    localStorage.removeItem("xano_refresh_token");
    localStorage.removeItem("xano_secret_key");
    localStorage.removeItem("xano_expiry_timestamp");
    localStorage.removeItem("accountID");

    localStorage.removeItem("tracking-code-added");
    localStorage.removeItem("access_token");

    setAccountID(null);
    setAccessToken(null);
  };

  return (
    <div className="stats-section">
      <div className="divider"></div>

      <div className="stat">
        <div className="leading-content">
          <div className="brand-icon green">
            <img
              className="brand-icon-img"
              alt="check icon"
              src={checkIcon}
              aria-label="check"
            />
          </div>
          <div className="amount-container">
            <div className="label">Listed</div>
            <div className="amount-and-change">
              <div className="amount">{listedAmount}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="stat">
        <div className="leading-content">
          <div className="brand-icon red">
            <img
              className="brand-icon-img"
              alt="error icon"
              src={exclamationCircleIcon}
            />
          </div>
          <div>
            <div className="label">Errors</div>
            <div className="amount-and-change">
              <div className="amount">{errorAmount}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="stat">
        <div className="leading-content">
          <div className="brand-icon blue">
            <img className="brand-icon-img" alt="clock icon" src={clockIcon} />
          </div>
          <div>
            <div className="label">Pending</div>
            <div className="amount-and-change">
              <div className="amount">{pendingAmount}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </div>
  );
};

export { ProductsStatus };
