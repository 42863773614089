import { useEffect, useRef, useState } from "react";
import { UseDashboardContext } from "../../../context/DasbhoardContext";
import { chachingXano } from "../../../utils/chachingXanoAPI";

const DashboardProductTableCard = () => {
  const { isConnected, accountID } = UseDashboardContext();

  const productsRetrieved = useRef(false);
  const [products, setProducts] = useState([
    { name: "", key: 1, description: "" },
    { name: "", key: 2, description: "" },
    { name: "", key: 3, description: "" },
  ]);

  useEffect(() => {
    if (isConnected === true && productsRetrieved.current === false) {
      const fetchData = async () => {
        try {
          const response = await chachingXano.get(
            `/chaching/accounts/${accountID}/product_errors`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "xano_auth_token"
                )}`,
              },
            }
          );

          response.data = [
            { name: "", key: 1, description: "" },
            { name: "", key: 2, description: "" },
            { name: "", key: 3, description: "" },
          ];

          if (response.data.length === 0) return;
          setProducts(response.data);
        } catch (err) {
          console.log(err);
        }
      };

      fetchData();
      return () => (productsRetrieved.current = true);
    }
  }, [isConnected, accountID]);

  const displayProductRows = () => {
    return products.map((product) => {
      const isEven = products.indexOf(product) % 2 === 0;

      return (
        <tr key={product.key}>
          <td className={isEven ? "table-cell grey name" : "table-cell name"}>
            {product.name}
          </td>
          <td className={isEven ? "table-cell grey sku" : "table-cell sku"}>
            {product.sku}
          </td>
          <td
            className={
              isEven ? "table-cell grey description" : "table-cell description"
            }
          >
            {product.description}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      {isConnected && (
        <div className="section">
          <div className="content-header">
            <div className="heading-container">
              <div className="heading large">Item level issues</div>
            </div>
            <div className="supporting-text-frame">
              <div className="supporting-text">
                Items below are products that are pending system review or have
                issues that need resolving before they can successfully list to
                ChaChing.
              </div>
            </div>
          </div>
          <table className="product-table">
            <thead>
              <tr>
                <th className="table-header-title name">Name</th>
                <th className="table-header-title sku">SKU</th>
                <th className="table-header-title description">
                  Issue description
                </th>
              </tr>
              <tr>
                <td className="header-divider"></td>
                <td className="header-divider"></td>
                <td className="header-divider"></td>
              </tr>
            </thead>
            <tbody>{displayProductRows()}</tbody>
          </table>
        </div>
      )}
    </>
  );
};

export { DashboardProductTableCard };
