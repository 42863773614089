import { createContext, useContext } from "react";
import { useState } from "react";

export const OverviewCardContext = createContext();

export const OverviewCardContextProvider = (props) => {
  const [accounts, setAccounts] = useState([]);

  const getAccounts = (response) => {
    if (!response) return;
    let accounts = response.data;

    accounts = accounts.map((account) => {
      const { id, name } = account;
      return { id, name };
    });

    setAccounts(accounts);
  };

  return (
    <OverviewCardContext.Provider
      value={{
        accounts,
        setAccounts,
        getAccounts,
      }}
    >
      {props.children}
    </OverviewCardContext.Provider>
  );
};

export const UseOverviewCardContext = () => {
  return useContext(OverviewCardContext);
};
