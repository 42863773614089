import { RefeshButton } from "./RefreshButton";
import { ConnectionStatusInformation } from "./ConnectionStatusInformation";
import { SignInStep } from "./SignInStep/SignInStep";
import { SelectAccountStep } from "./SelectAccountStep/SelectAccountStep";
import { TrackingTagStep } from "./TrackingTagStep/TrackingTagStep";
import { DisconnectSelectButton } from "./DisconnectSelectButton";
import { LastSyncElement } from "./LastSyncElement";
import { OverviewCardContextProvider } from "../../../context/OverviewCardContext";
import { SignInContextProvider } from "../../../context/SignInContext";

const DashboardOverviewCard = () => {
  return (
    <OverviewCardContextProvider>
      <div className="section medium-size-font">
        <div className="content-header">
          <div className="heading-parent">
            <div className="heading">Overview</div>
            <RefeshButton />
          </div>
          <LastSyncElement />
        </div>
        <div className="chaching-info-container">
          <ConnectionStatusInformation />
          <DisconnectSelectButton />
        </div>

        <SignInContextProvider>
          <SignInStep />
        </SignInContextProvider>
        <SelectAccountStep />
        <TrackingTagStep />
      </div>
    </OverviewCardContextProvider>
  );
};

export { DashboardOverviewCard };
