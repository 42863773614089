import axios from "axios";

const chachingXano = axios.create({
  baseURL: process.env.REACT_APP_XANO_API,
  headers: {
    "X-Data-Source": process.env.REACT_APP_X_DATA_SOURCE,
  },
});

export { chachingXano };
