import { useState } from "react";
import { UseDashboardContext } from "../../../../context/DasbhoardContext";
import { chachingXano } from "../../../../utils/chachingXanoAPI";
import { UseOverviewCardContext } from "../../../../context/OverviewCardContext";

const SelectAccountStep = () => {
  const { accessToken, accountID, setAccountID } = UseDashboardContext();

  const { accounts } = UseOverviewCardContext();

  const [selectedAccountID, setSelectedAccountID] = useState();

  const selectAccount = (e) => {
    setSelectedAccountID(e.target.value);
  };

  const handleConnectClick = async (e) => {
    if (selectedAccountID == null) return;

    await loginToXano();
    await connectAccount();
  };

  const loginToXano = async () => {
    const ekm_user_uuid = localStorage.getItem("ekm_user_uuid");

    const xanoLogin = {
      chaching_access_token: accessToken,
      ekm_user_uuid: ekm_user_uuid,
    };

    try {
      const response = await chachingXano.post("/auth/login", xanoLogin);
      // console.log(response);

      const { auth_token, refresh_token, secret_key, expiry } =
        response.data.data;

      const expiryTimestamp = Date.now() / 1000 + expiry;

      localStorage.setItem("xano_auth_token", auth_token);
      localStorage.setItem("xano_refresh_token", refresh_token);
      localStorage.setItem("xano_secret_key", secret_key);
      localStorage.setItem("xano_expiry_timestamp", expiryTimestamp);
    } catch (error) {
      console.log(error);
    }
  };

  const connectAccount = async () => {
    if (selectedAccountID === null) return;

    setAccountID(selectedAccountID);
    localStorage.setItem("accountID", selectedAccountID);
    localStorage.setItem("access_token", accessToken);

    const ekm_user_uuid = localStorage.getItem("ekm_user_uuid");

    try {
      const response = await chachingXano.post(
        "/chaching/connect_ekm_account",
        {
          account_id: selectedAccountID,
          ekm_user_uuid: ekm_user_uuid,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("xano_auth_token")}`,
          },
        }
      );
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    accessToken &&
    !accountID && (
      <>
        <div className="help-text">
          To use ChaChing, you will need to connect your account. Please select
          an option below to proceed. If you are signing up for the first time,
          once you create your user and merchant account on ChaChing, you must
          connect your account here.
        </div>
        <div className="select-account-container">
          <div className="select-account-label">Accounts</div>
          <select
            className="select-account"
            defaultValue={"DEFAULT"}
            onChange={(e) => selectAccount(e)}
          >
            <option
              value="DEFAULT"
              disabled
              hidden
              className="select-option-text"
            >
              Select
            </option>
            {accounts.map((account) => {
              return (
                <option
                  value={account.id}
                  key={account.id}
                  className="select-option-text"
                >
                  {account.name}
                </option>
              );
            })}
          </select>
        </div>
        <button
          className={
            selectedAccountID ? "dark-blue-button" : "dark-blue-button disabled"
          }
          onClick={(e) => {
            handleConnectClick();
          }}
        >
          connect
        </button>
      </>
    )
  );
};

export { SelectAccountStep };
