import { Header } from "../../components/Header";
import { DashboardMainContent } from "./DashboardMainContent";
const DashboardPage = () => {
  return (
    <div className="dashboard-page">
      <Header />
      <DashboardMainContent />
    </div>
  );
};

export { DashboardPage };
