import { Header } from "../../components/Header";
import "../../css/layout/pixel-install-page.css";
import { PixelPageMainContent } from "./PixelPageMainContent";

const PixelInstallPage = () => {
  return (
    <>
      <Header />
      <PixelPageMainContent />
    </>
  );
};

export { PixelInstallPage };
