import { UseDashboardContext } from "../../../../context/DasbhoardContext";
import { UseDisconnectModalContext } from "../../../../context/DisconnectModalContext";
import { chaching } from "../../../../utils/chachingAPI";
import { chachingXano } from "../../../../utils/chachingXanoAPI";
import { UseSignInContext } from "../../../../context/SignInContext";
import { UseOverviewCardContext } from "../../../../context/OverviewCardContext";

const SignInForm = () => {
  const { loginVisible } = UseSignInContext();
  const { accessToken, setAccessToken, failedLoginCount, setFailedLoginCount } =
    UseDashboardContext();

  const { setLoggedOut } = UseDisconnectModalContext();
  const { getAccounts } = UseOverviewCardContext();

  const triggerSubmit = async (e) => {
    e.preventDefault();
    const loginCredentials = await getloginCredentials();
    const chachingResponse = await loginToChaching(loginCredentials);
    const XanoResponse = await storeAdvertiser(chachingResponse);
    getAccounts(XanoResponse);
  };

  const getloginCredentials = () => {
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    let loginCredentials = {
      email: email,
      password: password,
    };
    return loginCredentials;
  };

  const loginToChaching = async (loginCredentials) => {
    if (!loginCredentials) return;

    try {
      const chachingResponse = await chaching.post(
        "/auth/session",
        loginCredentials
      );

      const { access_token } = chachingResponse.data;

      setAccessToken(access_token);
      return chachingResponse.data;
    } catch (error) {
      setFailedLoginCount(failedLoginCount + 1);

      console.log(error);
    }
  };

  const storeAdvertiser = async (loginData) => {
    if (!loginData) return;

    try {
      const response = await chachingXano.post("/chaching/login", loginData);

      setLoggedOut(false);

      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return (
    loginVisible &&
    !accessToken && (
      <form className="login-form">
        <div className="login-input-container">
          <label
            className="login-input-label"
            htmlFor="email"
            autoComplete="off"
          >
            Email
          </label>
          <input type="text" className="login-input" id="email" name="email" />
        </div>
        <div className="login-input-container">
          <label className="login-input-label" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            className="login-input"
            id="password"
            name="password"
          />
        </div>
        <button
          className="dark-blue-button"
          type="submit"
          onClick={(e) => {
            triggerSubmit(e);
          }}
        >
          Login
        </button>
      </form>
    )
  );
};

export { SignInForm };
