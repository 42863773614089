import mailIcon from "../../../assets/dashboard/Mail.svg";

const DashboardResourcesCard = () => {
  const openEmail = () => {
    return (window.location.href = "mailto:support@chaching.me");
  };
  return (
    <div className="section medium-size-font">
      <div className="content-header">
        <div className="heading-parent">
          <div className="heading">Resources</div>
        </div>
        <div className="supporting-text">Contact Support</div>
      </div>
      <button className="contact-button" onClick={() => openEmail()}>
        <img src={mailIcon} alt="Mail" className="contact-button-mail-icon" />
        <div className="contact-button-text">Email Us</div>
      </button>
    </div>
  );
};

export { DashboardResourcesCard };
