import { useEffect, useRef, useState } from "react";
import checkIcon from "../../../../assets/dashboard/check.svg";
import { UseDashboardContext } from "../../../../context/DasbhoardContext";
import { chachingXano } from "../../../../utils/chachingXanoAPI";

const CampaignsStatus = () => {
  const { isConnected, accountID } = UseDashboardContext();
  const campaignsStatusRetrieved = useRef(false);

  const [activeAmount, setActiveAmount] = useState(0);

  useEffect(() => {
    if (isConnected && campaignsStatusRetrieved.current === false) {
      const fetchData = async () => {
        try {
          const response = await chachingXano.get(
            `chaching/accounts/${accountID}/campaigns_status`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "xano_auth_token"
                )}`,
              },
            }
          );
          setActiveAmount(response.data.active);
        } catch (err) {
          console.log(err);
        }
      };

      fetchData();
      return () => (campaignsStatusRetrieved.current = true);
    }
  }, [isConnected]);

  return (
    <div className="stats-section">
      <div className="divider"></div>

      <div className="stat">
        <div className="leading-content">
          <div className="brand-icon green">
            <img className="brand-icon-img" alt="check icon" src={checkIcon} />
          </div>
          <div>
            <div className="label">Active</div>
            <div className="amount-and-change">
              <div className="amount">{activeAmount}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </div>
  );
};

export { CampaignsStatus };
