import chachingIcon from "../../../assets/dashboard/channel--chaching.svg";
import { UseDashboardContext } from "../../../context/DasbhoardContext";

const ConnectionStatusInformation = () => {
  const { isConnected } = UseDashboardContext();

  return (
    <>
      <img className="channel-chaching" alt="" src={chachingIcon} />

      <div className="connection-status-container">
        <div className="channel-name">
          <div className="chaching">ChaChing</div>
          <div className={isConnected ? "badge green" : "badge red"}>
            {isConnected ? "Connected" : "Disconnected"}
          </div>
        </div>
        <div className="secondary-text">By ChaChing</div>
      </div>
    </>
  );
};

export { ConnectionStatusInformation };
