import { createContext, useContext } from "react";
import { useState } from "react";

export const SignInContext = createContext();

export const SignInContextProvider = (props) => {
  const [loginVisible, setLoginVisible] = useState(false);

  return (
    <SignInContext.Provider
      value={{
        loginVisible,
        setLoginVisible,
      }}
    >
      {props.children}
    </SignInContext.Provider>
  );
};

export const UseSignInContext = () => {
  return useContext(SignInContext);
};
