import { UseDashboardContext } from "../../../../context/DasbhoardContext";
import { ProductsStatus } from "../ProductsStatus";

const DashboardProductsStatusCard = () => {
  const { isConnected } = UseDashboardContext();

  return (
    <>
      {isConnected && (
        <div className="section">
          <div className="content-header">
            <div className="heading-parent">
              <div className="heading large">Listed products</div>
            </div>
            <div className="supporting-text">
              View the status of products added to ChaChing or update existing
              listings.
            </div>
          </div>
          <ProductsStatus />
        </div>
      )}
    </>
  );
};

export { DashboardProductsStatusCard };
