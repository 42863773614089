import { UseDashboardContext } from "../../../../context/DasbhoardContext";
import { CampaignsStatus } from "../CampaignsStatus/CampaignsStatus";
import { CampaignButton } from "../CampaignButton/CampaignButton.jsx";
const DashboardCampaignsStatusCard = () => {
  const { isConnected } = UseDashboardContext();

  return (
    <>
      {isConnected && (
        <div className="section">
          <div className="content-header">
            <div className="heading-parent">
              <div className="heading large">Campaign</div>
              <CampaignButton />
            </div>
            <div className="supporting-text">
              View the status of your campaigns.
            </div>
          </div>

          <CampaignsStatus />
        </div>
      )}
    </>
  );
};

export { DashboardCampaignsStatusCard };
