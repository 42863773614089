import { useState } from "react";
import externalLinkIcon from "../../../../assets/dashboard/launch.svg";
import { Link } from "react-router-dom";
import { UseDashboardContext } from "../../../../context/DasbhoardContext";

const TrackingTagStep = () => {
  const { accessToken, accountID, trackingCodeAdded, setTrackingCodeAdded } =
    UseDashboardContext();
  const [checked, setChecked] = useState(false);

  const handleCheck = () => {
    if (checked === true) {
      setChecked(false);
    } else if (checked === false) {
      setChecked(true);
    }
  };

  const redirectUser = () => {
    window.open("/installation", "_blank", "noreferrer");
  };

  const handleConfirmButtonClick = () => {
    if (checked === false) return;

    localStorage.setItem("tracking-code-added", "true");
    setTrackingCodeAdded(true);
    setChecked(false);
  };

  return (
    accessToken &&
    accountID &&
    !trackingCodeAdded && (
      <>
        <div className="help-text">
          Now please follow the tracking tag installation guide. Once that is
          completed, let us know below. We will verify you have successfully
          installed the tracking tag and you will be officially connected on
          ChaChing.
        </div>
        <button
          className="dark-blue-button"
          onClick={() => {
            redirectUser();
          }}
        >
          <div>begin Tracking Tag installation</div>
          <img
            className="external-link-icon white"
            src={externalLinkIcon}
            alt="external link"
          />
        </button>
        <hr className="line-break" />
        <div className="confirm-step-container">
          <div className="confirm-title">Confirmation Step</div>
          <div className="confirm-checkbox-container">
            <input
              type="checkbox"
              className="confirm-checkbox"
              id="checkbox"
              onClick={() => {
                handleCheck();
              }}
            />
            <label className="confirm-label-text">
              I confirm I have added the Tracking Code
            </label>
          </div>
          <button
            className={
              checked ? "dark-blue-button" : "dark-blue-button disabled"
            }
            onClick={() => {
              handleConfirmButtonClick();
            }}
          >
            confirm
            <Link to="route" target="_blank" rel="noopener noreferrer" />
          </button>
          <Link to="route" target="_blank" rel="noopener noreferrer" />
        </div>
      </>
    )
  );
};

export { TrackingTagStep };
